import { Alert } from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../firebase-config/firebase";

export type Theme = {
  id: string;
  name: string;
};

export class ThemesService {
  async getAllData(): Promise<Theme[]> {
    const themesCollection = collection(firestore, "themes");
    const themesSnapshot = await getDocs(themesCollection);
    const themesData = themesSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));
    return themesData;
  }
  async getById(id: string): Promise<Theme> {
    const pictureDocRef = doc(firestore, "themes", id);
    const themeSnapshot = await getDoc(pictureDocRef);
    const themeData = themeSnapshot.data() as Theme;
    return themeData;
  }

  async create(name: string): Promise<void> {
    const themesRef = collection(firestore, "themes");
    const theme = {
      name: name,
    };
    await addDoc(themesRef, theme);
  }

  async update(name: string, id: string) {
    const updatedData: any = { name };
    const themeRef = doc(firestore, "themes", id);

    await updateDoc(themeRef, updatedData);
  }

  async delete(id: string): Promise<void> {
    const themeRef = collection(firestore, "themes");
    const ThemeDoc = doc(themeRef, id);
    await deleteDoc(ThemeDoc);
  }
}
