import { useState } from "react";
import { TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config/firebase";
import { useNavigate } from "react-router-dom";

type IFormInput = {
  email: string;
  password: string;
};

function LoginForm() {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<IFormInput>();
  const [error, setError] = useState("");

  const login = async (data: IFormInput) => {
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then(() => {
        navigate("../admin/dashboard");
      })
      .catch((err: any) => setError(err.message));
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card">
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/melodie-app.appspot.com/o/images%2F13.jpg?alt=media&token=a0b0a30f-50a8-42da-aa34-2c94934ea469"
                    alt="login form"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form onSubmit={handleSubmit(login)}>
                      <div className="form-outline mb-4">
                        <Controller
                          name={"email"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              onChange={onChange}
                              value={value}
                              label={"email"}
                              variant="outlined"
                              sx={{
                                width: "90%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <Controller
                          name={"password"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              type="password"
                              onChange={onChange}
                              value={value}
                              label={"password"}
                              variant="outlined"
                              sx={{
                                width: "90%",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="submit"
                          color="primary"
                        >
                          connexion
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
