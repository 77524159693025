import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  startAt,
} from "firebase/firestore";

import { firestore } from "../../firebase-config/firebase";
import { FormValues } from "../types/types";

export type Picture = {
  id: string;
  url: string;
  description: string;
  theme: string;
  title: string;
};
export type PictureToCreate = {
  image: { url: string };
  description: string;
  theme: string;
  title: string;
};

export class PicturesService {
  async getAllData(): Promise<Picture[]> {
    const picturesCollection = collection(firestore, "picturesArt");
    const picturesSnapshot = await getDocs(picturesCollection);
    const picturesData = picturesSnapshot.docs.map((doc) => ({
      id: doc.id,
      url: doc.data().image.url,
      description: doc.data().description,
      theme: doc.data().theme,
      title: doc.data().title,
    }));
    return picturesData;
  }

  async getAllDataPaginate(
    pageNumber: number,
    pageSize: number
  ): Promise<Picture[]> {
    const picturesCollection = collection(firestore, "picturesArt");
    const picturesQuery = query(
      picturesCollection,
      orderBy("title"),
      limit(pageSize)
    );

    if (pageNumber > 1) {
      // Récupérer le dernier document de la page précédente
      const lastDocQuery = query(
        picturesCollection,
        orderBy("title"),
        limit(pageSize),
        startAfter((pageNumber - 2) * pageSize)
      );
      const lastDocSnapshot = await getDocs(lastDocQuery);
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1];

      // Récupérer les documents à partir du dernier document de la page précédente
      const picturesSnapshot = await getDocs(
        query(
          picturesCollection,
          orderBy("title"),
          limit(pageSize),
          startAfter(lastDoc)
        )
      );

      const picturesData = picturesSnapshot.docs.map((doc) => ({
        id: doc.id,
        url: doc.data().image.url,
        description: doc.data().description,
        theme: doc.data().theme,
        title: doc.data().title,
      }));

      return picturesData;
    } else {
      // Récupérer la première page
      const picturesSnapshot = await getDocs(picturesQuery);

      const picturesData = picturesSnapshot.docs.map((doc) => ({
        id: doc.id,
        url: doc.data().image.url,
        description: doc.data().description,
        theme: doc.data().theme,
        title: doc.data().title,
      }));
      return picturesData;
    }
  }

  async getTotalCount(): Promise<number> {
    const querySnapshot = await getDocs(collection(firestore, "picturesArt"));
    return querySnapshot.size;
  }

  async getTotalPages(pageSize: number): Promise<number> {
    const querySnapshot = await getDocs(collection(firestore, "picturesArt"));
    const totalCount = querySnapshot.size;
    const totalPages = Math.ceil(totalCount / pageSize);
    return totalPages;
  }

  async getById(id: string): Promise<Picture> {
    const pictureDocRef = doc(firestore, "picturesArt", id);
    const pictureSnapshot = await getDoc(pictureDocRef);
    const pictureData = pictureSnapshot.data() as Picture;
    return pictureData;
  }

  async create(imageUrl: string, data: FormValues): Promise<String> {
    const picturesRef = collection(firestore, "picturesArt");
    const picture = {
      description: data.description,
      theme: data.theme,
      title: data.title,
      image: { url: imageUrl },
    };
    await addDoc(picturesRef, picture);
    return "ok";
  }

  async update(data: FormValues, id: string) {
    const picActual = await this.getById(id);
    const updatedData: any = {};

    if (picActual) {
      if (data.description !== "") {
        updatedData.description = data.description;
      }
      if (data.theme !== "") {
        updatedData.theme = data.theme;
      }
      if (data.title !== "") {
        updatedData.title = data.title;
      }
    }

    if (Object.keys(updatedData).length > 0) {
      const pictureRef = doc(firestore, "picturesArt", id);
      await updateDoc(pictureRef, updatedData);
    }
  }

  async delete(pictureId: string): Promise<void> {
    const picturesRef = collection(firestore, "picturesArt");
    const pictureDoc = doc(picturesRef, pictureId);
    await deleteDoc(pictureDoc);
  }
}
