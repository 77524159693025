import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { StarBorder } from "@mui/icons-material";
import { Collapse, ListItemIcon } from "@mui/material";

const useStyles = makeStyles({
  modeChoice: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
});

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  { key: 1, title: "Accueil", link: "/home" },
  { key: 2, title: "Galerie", link: "/galerie" },
  { key: 3, title: "À propos", link: "/biographie" },
];

export default function DrawerAppBar(props: Props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState(
    location.pathname
  );
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  const drawer = (
    <>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Melardie
        </Typography>
        <Divider />
        <List>
          {navItems.map((item) => (
            <ListItemButton
              component={Link}
              to={item.link}
              sx={{ textAlign: "center" }}
            >
              <ListItemText secondary={item.title} />
            </ListItemButton>
          ))}
        </List>{" "}
      </Box>
      <div className={classes.modeChoice}>
        <Divider />
        {/* <ToggleColorMode /> */}
      </div>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: currentLocation === "/" ? "none" : "block",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
    >
      {/* <AppBar sx={{ background: "black" }} component="nav"> */}
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" }, color: "black" }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            display: { xs: "flex", sm: "block", lg: "flex" },
            justifyContent: { xs: "center", sm: "start", lg: "start" },
          }}
        >
          Melardie
        </Typography>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              lg: "flex",
              justifyContent: "center",
            },
          }}
        >
          {/* <ToggleColorMode /> */}
          {navItems.map((item) => (
            <Link
              style={{
                textDecoration: "none",
                paddingLeft: "15px",
                paddingRight: "15px",

                fontSize: "15px",
                transition: ".5s cubic-bezier(.68,-.55,.27,1.55)",
              }}
              to={item.link}
            >
              <Button sx={{ color: "black", fontWeight: "bold" }}>
                {item.title}
              </Button>
            </Link>
          ))}
        </Box>
      </Toolbar>
      {/* </AppBar> */}

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export const ButtonNavigation = styled(MuiButton)((props) => ({
  display: "inline-block",
  position: "relative",
  color: "#0087ca",

  "&:after": {
    position: "absolute",
    width: "100%",
    transform: "scaleX(0)",
    height: "2px",
    bottom: 0,
    left: 0,
    backgroundColor: "#0087ca",
    transformOrigin: "bottom right",
    transition: "transform 0.25s ease-ou",
  },

  "&:hover:after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));
