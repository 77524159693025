import { createContext, useState, useEffect } from "react";
import { auth } from "./firebase-config/firebase";

export const UserContext = createContext();

export function AuthContextProvider(props) {
  const signUp = (email, pwd) =>
    auth.createUserWithEmailAndPassword(email, pwd);

  const signIn = (email, pwd) => auth.signInWithEmailAndPassword(email, pwd);

  const [currentUser, setCurrentUser] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setCurrentUser(currentUser);
      setLoadingData(false);
    });

    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{ signUp, currentUser, signIn }}>
      {!loadingData && props.children}
    </UserContext.Provider>
  );
}
