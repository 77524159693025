import * as React from "react";
import { styled } from "@mui/material/styles";
import { Modal, Box, Typography } from "@mui/material";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default StyledModal;
