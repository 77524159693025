import { Suspense } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./front/pages/login";
import Gallery from "./front/pages/galery/gallery_art_screen";
import About from "./front/pages/about";
import Home from "./front/pages/home/Home";
import Navbar from "./components/navbar/navbar";
import Admin from "./admin/pages/routes/admin_route";
import { Dashboard } from "./admin/pages/homeDashboard";
import MyDrawer from "./admin/component/Drawer/Drawer";

function App() {
  return (
    // <ThemeHandler>
    //   <CssBaseline />

    // <RecoilRoot>
    <Suspense fallback="">
      <div className="App">
        {" "}
        <Navbar />
        <Routes>
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/dashboard/" element={<Dashboard />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/galerie" element={<Gallery />} />
          <Route path="/biographie" element={<About />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Suspense>
    // </RecoilRoot>

    // </ThemeHandler>
  );
}

export default App;
