import { styled } from "@mui/styles";
import { blue } from "@mui/material/colors";
import { Button } from "@mui/material";
import SharedButtonStyles from "./ButtonBase.js";

const ButtonSubmit = styled(Button)({
  ...SharedButtonStyles,
  backgroundColor: blue[500],
  color: "#fff",
  "&:hover": {
    backgroundColor: blue[700],
    cursor: "pointer",
  },
});
export default ButtonSubmit;
