import { useState } from "react";
import {
  Button,
  Modal,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  styled,
  useMediaQuery,
  Input,
} from "@mui/material";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase-config/firebase";
import { FormValues } from "../../types/types";
import Form from "../Form/Form";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { PicturesService } from "../../services/PicturesService";
import ButtonSubmit from "../styles/Button/ButtonSubmit";
import ButtonCancel from "../styles/Button/ButtonCancel";
import ButtonPrevious from "../styles/Button/ButtonPrevious";
import ButtonNext from "../styles/Button/ButtonNext";
import StyledModal from "../styles/Modal/ModalBase";
import StyledBox from "../styles/Box/Box";

export interface PictureChangeCallback {
  (): Promise<void>;
}

type Props = {
  editmode?: boolean;
  open: boolean;
  id: string;
  onClose: () => void;
  onPictureChange?: PictureChangeCallback;
};

const picturesService: PicturesService = new PicturesService();

const UploadModal: React.FC<Props> = ({
  open,
  onClose,
  editmode,
  id,
  onPictureChange,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [key, setKey] = useState<any>(null);

  const methods = useForm();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleAddFile = async () => {
    if (file) {
      const storageRef = ref(storage, "images/" + file.name);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setImageUrl(url);
      setIsUpload(true);
    }
  };

  const resetFile = () => {
    setFile(null);
    setImageUrl(null);
    setIsUpload(false);
    setKey(Date.now());
  };

  const handleNext = async () => {
    if (activeStep === 0 && isUpload) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const onSubmit: SubmitHandler<any> = async (data: FormValues) => {
    if (!editmode && imageUrl) {
      picturesService.create(imageUrl, data);
    } else {
      picturesService.update(data, id);
      onPictureChange && onPictureChange();
    }
    onClose();
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <StyledModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "90%",
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: 600,
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "90%" : 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
          >
            {!editmode && (
              <Step>
                <StepLabel>Ajouter l'image </StepLabel>
                <StepContent>
                  <div>
                    <UploadButton as="label">
                      {!file ? "Choisir le fichier" : "Fichier choisi"}
                      <FileInput
                        key={key} // Utilisation de la clé unique
                        id="file-upload"
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFile(e.target.files?.[0] || null);
                        }}
                      />
                      {file && (
                        <span
                          style={{
                            display: "block",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {file.name}
                        </span>
                      )}
                    </UploadButton>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {file && (
                      <Button
                        sx={{ justifyContent: "flex-start" }}
                        onClick={handleAddFile}
                      >
                        Valider le fichier
                      </Button>
                    )}
                    {file && (
                      <Button
                        sx={{ justifyContent: "flex-start" }}
                        onClick={resetFile}
                      >
                        Effacer le fichier
                      </Button>
                    )}
                  </div>
                  {imageUrl && (
                    <ButtonNext
                      sx={{ justifyContent: "flex-start" }}
                      disabled={!isUpload}
                      onClick={handleNext}
                    >
                      Suivant
                    </ButtonNext>
                  )}
                </StepContent>
              </Step>
            )}
            <Step>
              {!editmode ? (
                <StepLabel>Details de l'oeuvre</StepLabel>
              ) : (
                <StepLabel>Modifier l'oeuvre</StepLabel>
              )}

              <StepContent>
                <Form id={id} />
                {!editmode && (
                  <ButtonPrevious
                    sx={{ marginTop: "10px" }}
                    onClick={handleBack}
                  >
                    Précedent
                  </ButtonPrevious>
                )}
                <ButtonContainer>
                  <ButtonCancel onClick={onClose} variant="contained">
                    Annuler{" "}
                  </ButtonCancel>
                  <ButtonSubmit
                    onClick={methods.handleSubmit(onSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    {!editmode ? "Créer l'oeuvre" : "Editer l'oeuvre"}
                  </ButtonSubmit>
                </ButtonContainer>
              </StepContent>
            </Step>
          </Stepper>
        </StyledBox>
      </StyledModal>
    </FormProvider>
  );
};

export default UploadModal;

const ButtonContainer = styled("div")({
  position: "absolute",
  bottom: 0,
  right: 0,
  margin: "1rem",
  "& >  button:first-of-type": {
    marginRight: "8px",
  },
});

const UploadButton = styled(Button)({
  backgroundColor: "#0377c2",
  color: "#fff",
  padding: "6px 12px",
  borderRadius: "4px",
  fontSize: "16px",
  cursor: "pointer",
  textAlign: "center",
  width: "40%",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#0377c2",
  },
});

const FileInput = styled(Input)({
  display: "none",
});
