import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Dialog, Skeleton, SwipeableDrawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogCustom from "./dialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function trunkString(value: string, length: number) {
  const valueLength = value.length;

  if (valueLength > length) {
    const newValue = value.slice(0, 60) + " " + "[...]";
    return newValue;
  } else return value;
}

export default function ArtCard(props: any) {
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        //position: matchesDesktop && "fixed",
        maxWidth: 345,
        height: "100%",
        backgroundColor: "transparent",
      }}
    >
      <CardMedia
        sx={{
          //  position: matchesDesktop && "absolute",
          height: "auto",
          width: "100%",
          borderRadius: "4px",
        }}
        component="img"
        image={props.art?.data.image.url}
      />{" "}
      {!props.adminMode && (
        <CardContent
          sx={{ position: "relative", backgroundColor: "transparent" }}
        >
          <Typography variant="body2" color="text.secondary">
            {trunkString(props.art?.data.description, 20)}
          </Typography>
          <DialogCustom
            buttonOpen={"En apprendre plus"}
            data={props.art?.data}
          />
        </CardContent>
      )}
      <CardActions sx={{ position: "relative" }}></CardActions>
    </Box>
  );
}
