import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const auth = getAuth();

const AppleButton = styled(Button)({
  background: "#000",
  color: "#fff",
  borderRadius: "25px",
  padding: "12px 24px",
  fontSize: "16px",
  fontWeight: "bold",
  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    background: "#111",
  },
});

export const ButtonLogout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return <AppleButton onClick={handleLogout}>Déconnexion</AppleButton>;
};
