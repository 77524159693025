import { Button, Container, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "../../../component/Modal/Modal";
import PaginationComponent from "../../../component/Pagination/Pagination";
import ButtonOpenModal from "../../../component/styles/Button/ButtonModal";
import { Picture, PicturesService } from "../../../services/PicturesService";

import PictureGrid from "./PictureGrid";

const picturesService: PicturesService = new PicturesService();

const PicturesContainer = () => {
  const [open, setOpen] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pictures, setPictures] = useState<Picture[]>([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePictureChange = async () => {
    const picturesData = await picturesService.getAllData();
    setPictures(picturesData);
  };

  useEffect(() => {
    const fetchPictures = async () => {
      const picturesData = await picturesService.getAllData();
      setPictures(picturesData);

      const totalCount = await picturesService.getTotalCount();
      setTotalPages(Math.ceil(totalCount / picturesData.length));
    };

    fetchPictures();
  }, []);

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <ButtonOpenModal
          sx={{ display: "flex", justifyContent: "start", marginLeft: 3 }}
          variant="contained"
          onClick={handleOpen}
        >
          Créer une oeuvre
        </ButtonOpenModal>

        <Modal
          editmode={false}
          open={open}
          onClose={handleClose}
          id={""}
          onPictureChange={handlePictureChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PictureGrid
          pictures={pictures}
          cols={0}
          onPictureChange={handlePictureChange}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <PaginationComponent
          onPageChange={handlePageChange}
          pageCount={totalPages}
        />
      </Grid> */}
    </Grid>
  );
};

const LeftAlignedButton = styled(Button)({
  float: "left",
});

export default PicturesContainer;
