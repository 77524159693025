import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import {
  Grid,
  GridSize,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";

import { firestore } from "../../../../firebase-config/firebase";
import CardPicture from "./Card";
import { PicturesService } from "../../../services/PicturesService";
import { PictureChangeCallback } from "../../../component/Modal/Modal";

export interface Picture {
  id: string;
  url: string;
  description: string;
  theme: string;
  title: string;
}

interface PictureGridProps {
  cols: GridSize;
  pictures: Picture[];
  onPictureChange?: PictureChangeCallback;
}

const PictureGrid: React.FC<PictureGridProps> = ({
  cols,
  pictures,
  onPictureChange,
}) => {
  return (
    <Grid container rowSpacing={5}>
      {pictures.map((picture) => (
        <Grid
          sx={{ height: "100%" }}
          key={picture.id}
          item
          xs={12}
          sm={6}
          md={3}
          lg={4}
        >
          <CardPicture
            key={picture.id}
            data={picture}
            onPictureChange={onPictureChange}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PictureGrid;
