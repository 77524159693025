import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    border: "2px solid blue",
  },
  copyright: {
    marginBottom: "40px",
    fontSize: "30px",
    display: "flex",
  },
  button: {
    boxShadow: "0px 0px 30px 0px #bec7bb",
    backgroundColor: "transparent",
    borderRadius: "15px",
    border: "5px solid #ebebeb",
    display: "inline-block",
    cursor: "pointer",
    color: "#ffffff",
    fontFamily: "Arial",
    fontSize: "20px",
    padding: "16px 100px",
    textDecoration: "none",
    "&:hover": {
      // background: "linear-gradient(to bottom, #f5f5f5 5%, #e2e9f0 100%)",
      boxShadow: "30px 0px 70px 0px #bec7bb",
      fontWeight: "bold",
    },
    "&:active": {
      position: "relative",
    },
  },
});

export default function Home() {
  const navigate = useNavigate();

  const classes = useStyles();

  const navigateToGallery = () => {
    navigate("/galerie", { replace: true });
  };
  const navigateToBiography = () => {
    navigate("/biographie", { replace: true });
  };

  return (
    <div className="Homepage">
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        {" "}
        <Typography
          sx={{
            fontWeight: "400",
            color: "white",
            alignItems: "center",
            display: "flex",
          }}
          variant="h1"
          gutterBottom
        >
          Mel'Ardie <div className={classes.copyright}>®</div>
        </Typography>
        <Typography
          sx={{ fontWeight: "400", color: "white", alignItems: "center" }}
          variant="h3"
          gutterBottom
        >
          Vitrine d'exposition{" "}
        </Typography>
        <Typography
          sx={{ marginBottom: "50px" }}
          className={classes.button}
          onClick={navigateToGallery}
        >
          DECOUVRIR LES OEUVRES
        </Typography>
        <Typography
          variant="button"
          className={classes.button}
          onClick={navigateToBiography}
        >
          DECOUVRIR L'ARTISTE
        </Typography>
      </Box>
    </div>
  );
}
