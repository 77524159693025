import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Picture } from "../../pages/homeDashboard/PicturesArt/PictureGrid";
import { Grid, TextField, Typography } from "@mui/material";
import { PictureChangeCallback } from "./Modal";
import { PicturesService } from "../../services/PicturesService";
import { SubmitHandler } from "react-hook-form";
import ButtonOpenModal from "../styles/Button/ButtonModal";
import ButtonCancel from "../styles/Button/ButtonCancel";
import ButtonSubmit from "../styles/Button/ButtonSubmit";
import StyledModal from "../styles/Modal/ModalBase";
import StyledBox from "../styles/Box/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface DialogDeleted {
  titlebutton: string;
  data: Picture;
  onPictureChange?: PictureChangeCallback;
}
const picturesService: PicturesService = new PicturesService();

const DialogDeletedPicture: React.FC<DialogDeleted> = ({
  titlebutton,
  data,
  onPictureChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const [securityField, setSecurityField] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(data.title);
  };

  const onSubmit: SubmitHandler<any> = () => {
    picturesService.delete(data.id);
    onPictureChange && onPictureChange();

    handleClose();
  };

  return (
    <div>
      <ButtonOpenModal variant="contained" onClick={handleClickOpen}>
        {titlebutton}
      </ButtonOpenModal>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledBox>
          <DialogTitle id="alert-dialog-title">
            Supprimer l'oeuvre "{data.title}""
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Description</Typography>
                  <p>{data.description}</p>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Thème</Typography>
                  <p>{data.theme}</p>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Saisir le nom de l'oeuvre dans le champs ci-dessous{" "}
                  </Typography>
                  <p>
                    <b>{data.title}</b>{" "}
                    <ContentCopyIcon
                      onClick={handleCopyToClipboard}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Sécurirté"
                    variant="outlined"
                    onChange={(e) => setSecurityField(e.target.value)}
                    value={securityField}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonCancel onClick={handleClose}>Annuler</ButtonCancel>
            <ButtonSubmit
              type="submit"
              variant="contained"
              onClick={onSubmit}
              autoFocus
              disabled={securityField !== data.title}
            >
              Supprimer
            </ButtonSubmit>
          </DialogActions>
        </StyledBox>
      </StyledModal>
    </div>
  );
};
export default DialogDeletedPicture;
