import * as React from "react";
import { styled } from "@mui/material/styles";
import { Modal, Box, Typography } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "90%",
  maxWidth: 500,
  backgroundColor: "#fff",
  borderRadius: 20,
  boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
  "&:before": {
    content: '""',
    position: "absolute",
    top: -60,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
    zIndex: -1,
  },
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    "& h2": {
      margin: 0,
      fontWeight: "bold",
      fontSize: "1.2rem",
    },
    "& button": {
      padding: "0.5rem",
      borderRadius: 8,
      border: "none",
      backgroundColor: "#F3F3F3",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
      "&:focus": {
        outline: "none",
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  "& .content": {
    padding: "1rem",
    "& p": {
      margin: 0,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
  "& .footer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "1rem",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    "& button": {
      padding: "0.5rem 1rem",
      borderRadius: 8,
      border: "none",
      backgroundColor: "#007AFF",
      color: "#fff",
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#006FE6",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#006FE6",
      },
    },
  },
}));

export default StyledBox;
