const SharedButtonStyles = {
  borderRadius: "10px",
  textTransform: "uppercase",
  letterSpacing: "2px",
  fontWeight: "bold",
  minWidth: 0,
  padding: "6px 16px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
};

export default SharedButtonStyles;
