import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ThemesService } from "../../../services/ThemesService";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

interface Props {
  themeCount: number;
}

function createData(id: string, name: string) {
  return { id, name };
}

const themesService: ThemesService = new ThemesService();

const ThemesTable: React.FC<Props> = ({ themeCount }) => {
  const [rows, setRows] = useState<any>([]);
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState<string>("");

  useEffect(() => {
    const fetchThemes = async () => {
      const data = await themesService.getAllData();
      const newRows = data.map((theme: { id: string; name: string }) =>
        createData(theme.id, theme.name)
      );
      setRows(newRows);
    };
    fetchThemes();
  }, [themeCount]);

  const handleEdit = (id: string) => {
    console.log(`Edit theme with id ${id}`);
    setEditingRowId(id);
    setRows((prevRows: any) =>
      prevRows.map((prevRow: any) =>
        prevRow.id === id ? { ...prevRow, editable: true } : prevRow
      )
    );
  };

  const handleSave = async (value: string, id: string) => {
    console.log(`Save theme with id ${id}`);
    try {
      await themesService.update(value, id);
      const updatedData = await themesService.getAllData();
      const rows = updatedData.map((theme: { id: string; name: string }) =>
        createData(theme.id, theme.name)
      );
      setRows(rows);
    } catch (error) {
      console.error(error);
    }
    setEditingRowId(null);
  };

  const handleCancel = (id: string) => {
    console.log(`Cancel edit theme with id ${id}`);
    setEditingRowId(null);
    setRows((prevRows: any) =>
      prevRows.map((prevRow: any) =>
        prevRow.id === id ? { ...prevRow, editable: false } : prevRow
      )
    );
  };

  const handleDelete = async (id: string) => {
    console.log(`Delete theme with id ${id}`);
    try {
      await themesService.delete(id);
      const updatedData = await themesService.getAllData();
      const rows = updatedData.map((theme: { id: string; name: string }) =>
        createData(theme.id, theme.name)
      );
      setRows(rows);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TableContainer sx={{ width: "50%" }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Identifiant du thème</TableCell>
            <TableCell align="left">Nom du thème</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">
                {row.editable ? (
                  <Input
                    value={row.name}
                    onChange={(e) => {
                      const updatedRow = { ...row, name: e.target.value };
                      setRows((prevRows: any) =>
                        prevRows.map((prevRow: any) =>
                          prevRow.id === row.id ? updatedRow : prevRow
                        )
                      );
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="validate"
                          onClick={() => handleSave(row.name, row.id)}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          onClick={() => handleCancel(row.id)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                ) : (
                  <Input
                    disabled
                    value={row.name}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(row.id)}
                          disabled={true}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ThemesTable;
