import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container, Divider, Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ResponsiveSection(props: any) {
  return (
    <Container sx={{ marginTop: "5%" }} maxWidth="xl">
      <Grid
        container
        spacing={{ xs: 8, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
        >
          {props.left}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          {props.right}
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        textAlign="center"
        sx={{
          marginTop: "10%",
          borderTop: "3px solid grey",
        }}
      />
    </Container>
  );
}
