import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

let config = {
  apiKey: "AIzaSyDvgerdO9tHYpIpW6XVc8lXVLiZ6bNbyKc",
  authDomain: "melodie-app.firebaseapp.com",
  projectId: "melodie-app",
  storageBucket: "melodie-app.appspot.com",
  messagingSenderId: "298316814035",
  appId: "1:298316814035:web:c4e95cba93e8edd5ef2626",
};
const app = initializeApp(config);

export const storage = getStorage(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
