import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  limit,
  query,
} from "firebase/firestore";
import { firestore } from "../firebase-config/firebase";

const db = collection(firestore, "/picturesArt");
const docRef = doc(db, "picturesArt");

export class PictureArtDataService {
  async getAll() {
    return (await getDocs(db)).docs;
  }

  create(
    //category: string,
    description: string,
    image: { url: string },
    theme: string,
    title: string
  ) {
    try {
      addDoc(db, { description, image, theme, title });
      return 200;
    } catch (error) {
      return { error, errorMessage: "Une erreur s'est produite" };
    }
  }
  update(id: string, value: any) {
    return updateDoc(docRef, value);
  }
  delete(id: string) {
    const docRef = doc(db, "picturesArt", id);

    return deleteDoc(docRef);
  }
}
