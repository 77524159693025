import { Grid } from "@mui/material";
import MyDrawer from "../../component/Drawer/Drawer";

export function Dashboard() {
  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item xs={12}>
        <MyDrawer />
      </Grid>
    </Grid>
  );
}
