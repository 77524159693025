import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import ArtCard from "../../../components/pictures_art_component";
import { PictureArtDataService } from "../../../services/picture_art.service";
import { DocumentData } from "firebase/firestore";
import { width } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Gallery() {
  const [datas, setDatas] = useState<any>([]);
  const [filter, setFilter] = useState();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = React.useState(1);

  const handleChange = (event: any, value: React.SetStateAction<number>) => {
    setPage(value);
  };
  // React.useEffect(() => {
  //   categorieService.getAll().onSnapshot((snapshot) => {
  //     setCategories(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  // }, []);
  const picturesArtservice: PictureArtDataService = new PictureArtDataService();

  useEffect(() => {
    const getData = async () => {
      const dataFetch = await picturesArtservice.getAll();
      let tab: { id: string; data: DocumentData }[] = [];
      dataFetch.map((doc) => {
        tab.push({ id: doc.id, data: doc.data() });
      });
      setDatas(tab);
    };
    getData();
  }, []);

  // function handleFilterChange(event: any) {
  //   setFilter(event.target.value);
  // }

  // function getFilteredList() {
  //   if (!filter) {
  //     return datas;
  //   }

  //   return datas.filter((item: any) => item.data.category === filter);
  // }

  //var filteredList = useMemo(getFilteredList, [filter, datas]);

  return (
    <div
      style={{
        width: "80%",
        margin: "auto",
        overflowY: "auto",
        height: "90vh",
      }}
    >
      <Grid sx={{}} container spacing={1} columnSpacing={1}>
        {datas.map((data: any, index: number) => (
          <Grid
            sx={{ display: "flex", justifyContent: "center" }}
            item
            xs={12}
            sm={4}
            md={4}
            key={index}
          >
            <ArtCard art={data} />
          </Grid>
        ))}
      </Grid>{" "}
    </div>
    /* <Grid item xs={12} lg={12}>
          <Typography color="text.primary">Filtrer la categorie</Typography>
          <FormControl sx={{ width: "300px", justifyContent: "start" }}>
            <Select
              displayEmpty
              input={<OutlinedInput />}
              placeholder=""
              value={filter ? filter : ""}
              label="Categorie"
              onChange={handleFilterChange}
            >
              {categories.map((category) => (
                <MenuItem value={category.id}>{category.data.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */
  );
}
