import { useState, useEffect, useRef } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { FormValues } from "../../types/types";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../../firebase-config/firebase";
import { collection, doc, query } from "firebase/firestore";
import { Picture, PicturesService } from "../../services/PicturesService";

type Props = {
  id?: string;
};

const picturesService: PicturesService = new PicturesService();

const UploadForm: React.FC<Props> = ({ id }) => {
  const methods = useFormContext<FormValues>();
  const { control, setValue, getValues } = useForm();

  const [selectedTheme, setSelectedTheme] = useState<any>("");
  const [data, setData] = useState<Picture>();

  const themesRef = collection(firestore, "themes");
  const q = query(themesRef);
  const [themes, loading, error] = useCollectionData(q);
  const themeSelectRef = useRef(null);

  const handleThemeChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedTheme(event.target.value as string | null);
  };

  useEffect(() => {
    const fetchPicture = async (id: string) => {
      const pic: Picture = await picturesService.getById(id);
      setData(pic);
    };
    if (id) fetchPicture(id);
  }, [id]);

  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      setValue("description", data.description);
      setValue("theme", data.theme);
      setSelectedTheme(data.theme);
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Titre</Typography>
        <Controller
          name={"title"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              {...methods?.register("title")}
              onChange={onChange}
              value={value ? value : getValues("title")}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Description</Typography>
        <Controller
          name={"description"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              {...methods?.register("description")}
              required
              value={value ? value : getValues("description")}
              onChange={onChange}
              multiline
              rows={3}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Theme</Typography>
        <Controller
          name={"theme"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              {...methods?.register("theme")}
              onChange={handleThemeChange}
              required
              inputRef={themeSelectRef} // Utilisation de register sur inputRef
              value={selectedTheme}
            >
              {loading && <MenuItem disabled>Loading themes...</MenuItem>}
              {error && <MenuItem disabled>Error loading themes.</MenuItem>}
              {themes &&
                themes.map((theme) => (
                  <MenuItem key={theme.id} value={theme.name}>
                    {theme.name}
                  </MenuItem>
                ))}
            </StyledSelect>
          )}
        />
      </Grid>
    </Grid>
  );
};

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  && {
    width: 100%;
  }
`;

export default UploadForm;
