import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { blue } from "@mui/material/colors";
import SharedButtonStyles from "./ButtonBase.js";

const ButtonPrevious = styled(Button)({
  ...SharedButtonStyles,
  backgroundColor: "transparent",
  border: `2px solid ${blue[500]}`,
  color: blue[500],
  "&:hover": {
    backgroundColor: blue[500],
    color: "#fff",
  },
});

export default ButtonPrevious;
