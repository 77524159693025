import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface State {
  value: string;
}

const initialState: State = {
  value: "",
};

export const urlPictureArtSlice = createSlice({
  name: "url",
  initialState,
  reducers: {
    add: (state, action) => {
      const newState = action.payload;
      state.value = newState;
    },
    decrement: (state) => {
      //  state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { add, decrement, incrementByAmount } = urlPictureArtSlice.actions;

export default urlPictureArtSlice.reducer;
