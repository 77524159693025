import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled, TextField, Box } from "@mui/material";
import { ThemesService } from "../../../../services/ThemesService";
import ButtonSubmit from "../../../../component/styles/Button/ButtonSubmit";
import ButtonCancel from "../../../../component/styles/Button/ButtonCancel";
import ButtonOpenModal from "../../../../component/styles/Button/ButtonModal";
import StyledModal from "../../../../component/styles/Modal/ModalBase";
import StyledBox from "../../../../component/styles/Box/Box";

interface Props {
  onSubmit: (name: string) => void;
}

const DialogCreateTheme: React.FC<Props> = ({ onSubmit }) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
    handleClose();
  };

  return (
    <div>
      <div style={{ justifyContent: "start", display: "flex" }}>
        <ButtonOpenModal onClick={handleOpen}>Créer un thème</ButtonOpenModal>
      </div>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox sx={{ height: 200, paddingLeft: 3 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Création d'un thème
          </Typography>

          <TextField
            sx={{ marginTop: 5 }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            id="outlined-basic"
            label="Nom du thème"
            variant="outlined"
          />
          <ButtonContainer>
            <ButtonCancel onClick={handleClose}>Annuler</ButtonCancel>

            <ButtonSubmit variant="contained" onClick={handleSubmit}>
              Créer
            </ButtonSubmit>
          </ButtonContainer>
        </StyledBox>
      </StyledModal>
    </div>
  );
};
export default DialogCreateTheme;
const ButtonContainer = styled("div")({
  position: "absolute",
  bottom: 0,
  right: 0,
  margin: "1rem",
  "& >  button:first-of-type": {
    marginRight: "8px",
  },
});
