import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Section from "../../../components/section";

export default function About() {
  const matchesDesktop = useMediaQuery("(min-width:901px)");
  const matchesMobile = useMediaQuery("(max-width:500px)");

  return (
    <>
      <Section
        left={
          <>
            <div>
              <Typography sx={{ justifyContent: "start" }} variant="h4">
                La galerie
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: "3%", alignItems: "center" }}
                color="text.secondary"
              >
                <>
                  Vous retrouverez ici mes créations uniques et personnelles
                  <br />
                  Je met un point d'honneur à ne faire aucune reproduction
                  <br />
                  Mes créations sont à la demande et personnalisées
                  <br />
                  <br />
                  Retrouvez mes oeuvres postées dans la galerie
                </>
              </Typography>
              <Button
                component={Link}
                to={"/galerie"}
                sx={{ marginTop: "10px" }}
                variant="outlined"
              >
                Acceder à la galerie
              </Button>
            </div>
            <div style={{ marginTop: "15%" }}>
              <Typography sx={{ justifyContent: "start" }} variant="h4">
                Mélodie Boute
              </Typography>
              <Typography
                sx={{ justifyContent: "start", fontWeight: "italic" }}
                variant="subtitle1"
              >
                Une personnalité, un art, une passion...
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: "3%", alignItems: "center" }}
                color="text.secondary"
              >
                <>
                  Vous retrouverez ici mes créations uniques et personnelles
                  <br />
                  Je met un point d'honneur à ne faire aucune reproduction
                  <br />
                  Mes créations sont à la demande et personnalisées
                </>
              </Typography>
            </div>
            <div style={{ marginTop: "15%" }}>
              <Typography sx={{ justifyContent: "start" }} variant="h4">
                Un projet ?
              </Typography>
              <Typography
                sx={{ justifyContent: "start", fontWeight: "italic" }}
                variant="subtitle1"
              >
                un cadeau à offrir, une recherche de création...
              </Typography>
              <Typography
                sx={{ justifyContent: "start", fontWeight: "italic" }}
                variant="subtitle2"
              >
                Contactez moi
              </Typography>
              <div style={{ margin: "10px" }}>
                <a href="https://www.instagram.com/melodebte/?hl=fr">
                  {" "}
                  <img
                    style={{ marginRight: "2%" }}
                    src={"%PUBLIC_URL%/instagram.png"}
                    alt="IG"
                    height={"50px"}
                  />
                </a>
                <a href="mailto:tony.herbert.97@gmail.com?subject=[MELARDIE] Demande de renseignements/devis">
                  <img
                    src={"%PUBLIC_URL%/mail.png"}
                    alt="mail"
                    height={"50px"}
                  />
                </a>
              </div>
            </div>
          </>
        }
        right={
          <Box sx={matchesMobile ? { maxWidth: 345 } : null}>
            {" "}
            <img
              width={"100%"}
              className="picture-first-section"
              src="https://art68538456.files.wordpress.com/2022/05/img_1704.jpg"
              alt="picture1"
            />
          </Box>
        }
      ></Section>
    </>
  );
}
