import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContextProvider, UserContext } from "../../../AuthContext";

export default function Admin() {
  const currentUser = useContext(UserContext);
  console.log(currentUser);

  if (!currentUser.currentUser) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
