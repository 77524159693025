import React, { useState } from "react";
import { Card, CardMedia, Button, styled } from "@mui/material";
import { Picture } from "./PictureGrid";
import ModalData, {
  PictureChangeCallback,
} from "../../../component/Modal/Modal";
import DialogDeletedPicture from "../../../component/Modal/DialogDeletedPicture";

interface CardInterface {
  data: Picture;
  onPictureChange?: PictureChangeCallback;
}

const CardPicture: React.FC<CardInterface> = ({ data, onPictureChange }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledCard>
        <StyledCardMedia image={data.url} />
      </StyledCard>
      <StyledButtonContainer>
        <StyledButton variant="contained" onClick={handleOpen}>
          Modifier
        </StyledButton>
        <ModalData
          id={data.id}
          editmode={true}
          open={open}
          onClose={handleClose}
          onPictureChange={onPictureChange}
        ></ModalData>
        <DialogDeletedPicture
          titlebutton="Supprimer"
          data={data}
          onPictureChange={onPictureChange}
        />
      </StyledButtonContainer>
    </>
  );
};
export default CardPicture;

const StyledCard = styled(Card)({
  height: "500px",
  width: "300px",
  display: "inline-block",
});

const StyledCardMedia = styled(CardMedia)({
  height: "100%",
  borderRadius: "12px",
});

const StyledButton = styled(Button)({
  margin: "10px",
});

const StyledButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
