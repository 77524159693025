import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { red } from "@mui/material/colors";
import SharedButtonStyles from "./ButtonBase.js";

const ButtonCancel = styled(Button)({
  ...SharedButtonStyles,
  backgroundColor: "transparent",
  border: `2px solid ${red[500]}`,
  color: red[500],
  "&:hover": {
    backgroundColor: red[500],
    color: "#fff",
  },
});

export default ButtonCancel;
