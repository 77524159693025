import { Button, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";

import Modal from "../../../component/Modal/Modal";
import PaginationComponent from "../../../component/Pagination/Pagination";
import { Picture, PicturesService } from "../../../services/PicturesService";
import { ThemesService } from "../../../services/ThemesService";
import ModalCreateTheme from "./components/ModalCreateTheme";
import ThemesTable from "./ThemesTable";

const themesService: ThemesService = new ThemesService();

const ThemesContainer = () => {
  const [themeCount, setThemeCount] = useState(0);

  const handleCreateTheme = async (value: string) => {
    themesService.create(value);
    const data = await themesService.getAllData();
    setThemeCount(data.length);
  };

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <ModalCreateTheme onSubmit={handleCreateTheme} />
      </Grid>
      <Grid item xs={12}>
        <ThemesTable themeCount={themeCount} />
      </Grid>
    </Grid>
  );
};

export default ThemesContainer;
